import $ from 'jquery'

import '@selectize/selectize'
import '@selectize/selectize/dist/css/selectize.css'
import './style.scss'

const initSelectize = function(root) {
  $(root).find('.selectize:not(.selectize-control):not(.selectize-dropdown)').each(function(index, el) {
    if (el.selectize || ($(el).hasClass('cascadingDropDown') && !$(el).hasClass('cascadingDropDown-inited'))) {
      return
    }

    const settings = $.extend({}, $(el).data())
    $(el).selectize(settings)
  })
}

const initTagInput = function(root) {
  $(root).find('.tag-input').each(function(index, el) {
    const selectize = $(el).selectize({
      delimiter: ',',
      persist: false,
      create(input) {
        return {
          value: input,
          text: input
        }
      }
    }).get(0).selectize

    $(el).on('disabled', function() {
      if (el.disabled) {
        selectize.disable()
        selectize.lock()
      } else {
        selectize.enable()
        selectize.unlock()
      }
    })
  })
}

$(document).on('content:loaded', function(event) {
  const root = event.target

  initSelectize(root)
  // initTagInput(root);
})

$(document).on({
  'turbolinks:before-cache'(event) {
    $(event.target).find('.selectize').each(function(index, el) {
      if (el.selectize) {
        el.selectize.destroy()
      }
    })
  }
})
