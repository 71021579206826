import $ from 'jquery'
import EasyMDE from 'easymde'
import 'easymde/dist/easymde.min.css'
import './style.scss'

const initMarkdown = function(root) {
  $(root).find('textarea.markdown, input.markdown').each(function(index, el) {
    const easymde = new EasyMDE({
      element: el,
      status: false,
      previewRender(plainText, preview) {
        const url = $(el).data('preview-url')
        const content = plainText

        $.ajax({
          url,
          method: 'POST',
          data: { content },
        }).done(function(data) {
          preview.innerHTML = data
        })

        return 'Loading...'
      }
    })

    if (el.rows) {
      $(easymde.codemirror.display.wrapper).css('minHeight', `${el.rows * 1.5}em`)
      $(easymde.codemirror.display.scroller).css('minHeight', `${el.rows * 1.5}em`)
    }
  })
}

$(document).on('content:loaded', function(event) {
  initMarkdown(event.target)
})
